import React, { useContext } from "react"
import hintsBackgroundImg from '../../../images/games/wordFinder/hintsBackground.png';
import HintPreview from "./HintPreview";
import CategoryPreview from "./CategoryPreview";
import { Category } from "../../../model/Game/WordFinder/Category";
import { SecureContext } from "../../_MyFloor/MyFloorApp";
import { AlphabetType, isRtlAlphabet } from "../../../model/AlphabetType";

interface Props {
    category: Category; 
}

const HintBoardPreview = (props : Props) => {
    const {category} = props;
    const {isRtl} = useContext(SecureContext);
    const useImgHints = !category.words.find(x => !x.hintImage); 
    
    return(
        <div className='hint-board-with-title' dir={isRtl ? 'rtl' : 'ltr'}>
            <CategoryPreview category={category} hideSoundIcon/>
            <div className={`hint-board`}>
                <img src={hintsBackgroundImg} className="hint-board-background" alt="background"/>
                <div className={`hints-container${useImgHints ? ' images' : ''}`}>
                    {category.words.map(x => 
                        <HintPreview 
                            key={x.id} 
                            hintImage={useImgHints ? x.hintImage : undefined} 
                            contentToFind={x.contentToFind} 
                            isRtl={isRtlAlphabet(category.alphabet)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default HintBoardPreview; 